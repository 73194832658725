import type { As } from '@chakra-ui/react';
import clsx from 'clsx';

import type { ChildrenType, PolymorphicProps } from '@endaoment-frontend/types';
import { ArrowIcon, BitcoinIcon } from '@endaoment-frontend/ui/icons';

import styles from './ActionButton.module.scss';
import { Button } from './Button';

type ActionButtonProps<Tag extends As> = PolymorphicProps<
  'button',
  Tag,
  {
    children?: ChildrenType;
    color?: 'black' | 'blue' | 'brand-2' | 'fund' | 'green' | 'org' | 'pink' | 'purple' | 'red' | 'violet';
    text?: string;
    subtext?: string;
    small?: boolean;
    spinny?: boolean;
  }
>;

export const ActionButton = <Tag extends As>({
  children = <BitcoinIcon width={20} color='currentColor' />,
  color = 'org',
  text,
  subtext,
  small = false,
  className,
  spinny = false,
  ...restProps
}: ActionButtonProps<Tag>) => {
  return (
    <Button
      className={clsx(styles['type-button'], className)}
      data-color={color}
      data-small={small}
      data-spinny={spinny}
      type='button'
      {...restProps}>
      <div className={styles['icon']}>{children}</div>
      {!!(text || subtext) && (
        <div className={styles['text']}>
          {!!text && <h4>{text}</h4>}
          {!!subtext && <p>{subtext}</p>}
        </div>
      )}
      <div>
        <ArrowIcon width={30} strokeWidth={1.5} />
      </div>
    </Button>
  );
};
