import { z } from 'zod';

import { uuidSchema } from '../general';
import { addressSchema, bigIntSchema } from '../web3';

import { evmTokenSchema, genericTokenSchema, otcTokenSchema } from './tokens';

export const entityTypeSchema = z.enum(['fund', 'org']);
export type EntityType = z.infer<typeof entityTypeSchema>;

export const entityLabelSchema = z.object({
  type: z.enum([...entityTypeSchema.options, 'subproject']),
  name: z.string(),
  id: uuidSchema,
});
export type EntityLabel = z.infer<typeof entityLabelSchema>;

export const stockTickerSchema = z.object({
  ticker: z.string(),
  name: z.string(),
});
export type StockTicker = z.infer<typeof stockTickerSchema>;

export const employeeStockPlanSchema = z.enum(['Exercised', 'ESPP', 'Restricted']);
export type EmployeeStockPlan = z.infer<typeof employeeStockPlanSchema>;

export const stockLotSchema = z.object({
  numberOfShares: z.number(),
  purchasePrice: z.number(),
  purchaseDate: z.string(),
  lotId: z
    .string()
    .regex(/^[a-z\d]*$/i)
    .optional(),
  employeeStockPlan: employeeStockPlanSchema.optional(),
});
export type StockLot = z.infer<typeof stockLotSchema>;

const stockListingSchema = stockTickerSchema.extend({ id: uuidSchema });

export const activitySubjectSchema = z.enum([...entityTypeSchema.options, 'user', 'featured']);
export type ActivitySubject = z.infer<typeof activitySubjectSchema>;

const genericActivitySchema = z.object({
  transactor: addressSchema,
  /** A timestamp */
  occurredAtUtc: z.string(),
  transactionHash: addressSchema.nullish(),
  type: z.string(),
  automated: z.boolean(),
  chainId: z.number(),
});
const valueTransferActivitySchema = genericActivitySchema.extend({
  // Can be either token amount or share amount
  amount: bigIntSchema,
  usdcAmount: bigIntSchema,
});

const customActivitySchema = z.object({
  type: z.literal('custom'),
  content: z.string(),
  /** A timestamp */
  occurredAtUtc: z.string(),
});

const donationActivitySchema = valueTransferActivitySchema.extend({
  type: z.literal('donation'),
  to: entityLabelSchema,
  token: z.union([genericTokenSchema, evmTokenSchema, otcTokenSchema]),
});

export const pledgeOutcomeSchema = z.enum(['Pending', 'AwaitingAssets', 'OnRamping', 'Success', 'Failure']);

const pledgeActivitySchema = genericActivitySchema.extend({
  transactor: addressSchema.nullish(),
  to: entityLabelSchema,
  pledgeId: uuidSchema,
  outcome: pledgeOutcomeSchema,
  chainId: z.number().nullish(),
});

const stockPledgeActivitySchema = pledgeActivitySchema.extend({
  type: z.literal('stock_donation_pledge'),
  stock: stockListingSchema,
  usdcAmount: bigIntSchema,
});

const fiatPledgeActivitySchema = pledgeActivitySchema.extend({
  type: z.enum(['fiat_donation_pledge', 'migration_donation_pledge']),
  usdcAmount: bigIntSchema,
});

const necPledgeActivitySchema = pledgeActivitySchema.extend({
  type: z.literal('nec_donation_pledge'),
  amount: bigIntSchema,
  usdcAmount: bigIntSchema.nullish(),
  token: z.union([otcTokenSchema, evmTokenSchema]),
});

export const portfolioTradeTypeSchema = z.enum(['Buy', 'Sell']);

const allocationActivitySchema = genericActivitySchema.extend({
  type: z.literal('portfolio_trade'),
  fund: entityLabelSchema,
  tradeType: portfolioTradeTypeSchema,
  usdcAmount: bigIntSchema,
  portfolio: z.object({
    name: z.string(),
    id: uuidSchema,
    ticker: z.string().nullish(),
  }),
  shares: bigIntSchema.nullish(),
  outcome: z.enum(['InTransit', 'Completed']),
});
const transferActivitySchema = valueTransferActivitySchema.extend({
  type: z.enum(['grant', 'internal_transfer']),
  from: entityLabelSchema,
  to: entityLabelSchema,
  token: z.union([genericTokenSchema, evmTokenSchema, otcTokenSchema]),
});

export type DonationActivity = z.infer<typeof donationActivitySchema>;
export type StockPledgeActivity = z.infer<typeof stockPledgeActivitySchema>;
export type FiatPledgeActivity = z.infer<typeof fiatPledgeActivitySchema>;
export type NecPledgeActivity = z.infer<typeof necPledgeActivitySchema>;
export type AllocationActivity = z.infer<typeof allocationActivitySchema>;
export type TransferActivity = z.infer<typeof transferActivitySchema>;

export const activitySchema = z.discriminatedUnion('type', [
  customActivitySchema,
  donationActivitySchema,
  stockPledgeActivitySchema,
  fiatPledgeActivitySchema,
  necPledgeActivitySchema,
  allocationActivitySchema,
  transferActivitySchema,
]);
export type Activity = z.infer<typeof activitySchema>;

export const donationPledgeActivitySchema = z.union([
  stockPledgeActivitySchema,
  fiatPledgeActivitySchema,
  necPledgeActivitySchema,
]);
export type DonationPledgeActivity = z.infer<typeof donationPledgeActivitySchema>;
